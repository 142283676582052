<template>
  <v-container fluid>
    <v-row>
      <v-col class="pt-0">
         <v-img eager class="separator-size d-inline-flex" src="assets/separator-celest-top.svg" />
      </v-col>
    </v-row>
    <v-row class="text-left pa-10">
      <v-col cols="12" lg="6" offset-lg="3">
        <h1 class="turquesa--text display-1 pb-5">EL NFT COLLECIONABLE</h1>
        <p class="chivo">
          Luego de la subasta el Token que representa al inmueble durante el proceso de venta se convertirá en un NFT 
          coleccionable con todo el legajo administrativo y periodístico del proceso y del proyecto, así como las fotos del inmueble y la escritura traslativa de dominio.</p>
        <p class="chivo">Este NFT será un criptoactivo y podrá comercializarse. En todo momento el tenedor del token tendrá acceso al material exclusivo del proceso en la plataforma <span class="font-weight-bold">TOKENFIT.</span></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NFTSection'
}
</script>

<style>

</style>